<template>
  <v-app>
    <v-app-bar app dark hide-on-scroll color="primary">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"/>
      <v-toolbar-title>JKONSULT</v-toolbar-title>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app width="300">
      <v-list>
        <v-subheader>Lapas</v-subheader>
        <v-list-item v-for="item in menu" :key="item.id" :to="item.href">
          <v-list-item-icon>
            <v-icon v-text="item.icon"/>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"/>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main class="grey lighten-4">
      <router-view/>
    </v-main>
    <v-footer dark color="blue darken-4" class="px-0 py-0">
      <v-col class="text-center" cols="12">
        <small>
          Autortiesības &copy; {{ new Date().getFullYear() }}, <b>SIA JKONSULT</b>.
          Visas tiesības aizsargātas.
        </small>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: 'Main',
  data: () => ({
    drawer: false,
    menu: [
      {
        text: 'Vadības panelis',
        icon: 'fa-tachometer-alt',
        href: '/dashboard'
      },
      {
        text: 'Budžeti',
        icon: 'fa-coins',
        href: '/budgets'
      },
      {
        text: 'Uzņēmumi',
        icon: 'fa-store-alt',
        href: '/companies'
      },
      {
        text: 'Reklāmas',
        icon: 'fa-ad',
        href: '/advertisements'
      },
      {
        text: 'Uzdevumi',
        icon: 'fa-briefcase',
        href: '/tasks'
      },
      {
        text: 'Rēķini',
        icon: 'fa-file-alt',
        href: '/invoices'
      },
      {
        text: 'Menedžeris',
        icon: 'fa-users',
        href: '/users'
      },
      {
        text: 'Log',
        icon: 'fa-clipboard-list',
        href: '/log'
      },
      {
        text: 'Keywords',
        icon: 'fa-ad',
        href: '/keywords'
      }
    ]
  })
}
</script>

<style scoped>

</style>
